import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { YoastSEO } from '../components/YoastSEO'
import ProductList from '../components/Posts/ProductList'
import { PageHeader } from '../components/PageHeader'
import { ImagePass } from '../components/Image/ImagePass'
import GatsbyLink from '../components/GatsbyLink'
import RenderContent from '../components/RenderContent'
import { decodeEntities } from '../utils/helpers'
import './product-category.scss'
import { NewsletterSignUp } from '../components/NewsletterSignUp'

export default class Category extends React.Component {
  render() {
    const { data, pageContext, location } = this.props
    const {
      postsOnPage,
      categories,
      thisCategory,
      site,
      yoast = [],
      wordpressWpSettings,
      siteSettings,
    } = data

    const { yoast_head } = thisCategory
    const { edges: posts, totalCount } = postsOnPage
    const { title: siteTitle } = wordpressWpSettings
    const { name: category, slug, acf, pathPrefix } = pageContext

    const { newsletterText } = siteSettings.options

    const isParent =
      location.pathname.split('/').length <= 3 &&
      !['/specials/', '/new/'].includes(location.pathname) // Check url for depth
    const isPaged = location.pathname.includes('/page/')
    const title =
      acf && acf.title
        ? `${acf.title}${isParent ? '' : ''}`
        : `${category} ${isParent ? '' : ''}`

    const thisCategoryId = pageContext.category_id
    const headerImage =
      thisCategory && thisCategory.acf.categoryHeaderImage
        ? thisCategory.acf.categoryHeaderImage
        : ''
    const thisParentCategoryId = pageContext.parent_category
    const listChildrentOfThis =
      pageContext.parent_category === 0 ? thisCategoryId : thisParentCategoryId

    return (
      <>
        <YoastSEO yoastHead={yoast_head} />

        <PageHeader
          headerTitle={title}
          headerBackgroundImage={headerImage}
          location={location}
        />

        <ProductList
          posts={posts}
          title={title}
          pageContext={pageContext}
          categories={categories.edges}
          siteMetadata={wordpressWpSettings}
          pathPrefix={pathPrefix}
        />

        {!isPaged && acf && (
          <>
            <section className="parent-category-intro">
              <div className="wrap">
                <div className="inner">
                  <div className="feature-image">
                    <div className="aspect">
                      {thisCategory.acf.featureImage && (
                        <ImagePass src={thisCategory.acf.featureImage} />
                      )}
                    </div>
                  </div>
                  <div className="content">
                    {thisCategory.acf &&
                      thisCategory.acf.categoryPageDescription && (
                        <RenderContent
                          content={thisCategory.acf.categoryPageDescription}
                        />
                      )}
                  </div>
                </div>
              </div>
            </section>
          </>
        )}

        <NewsletterSignUp content={newsletterText} />
      </>
    )
  }
}

export const pageQuery = graphql`
  query ProductCategoryPage($category_id: [Int], $limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      blogSlug
      wordpressUrl
      siteUrl
    }
    postsOnPage: allWordpressWpProducts(
      filter: { product_category: { in: $category_id } }
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...ProductListFields
        }
      }
    }
    thisCategory: wordpressWpProductCategory(
      wordpress_id: { in: $category_id }
    ) {
      name
      slug
      yoast_head
      acf {
        title
        categoryPageDescription
        categoryHeaderImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2100, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        featureImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 670, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
    categories: allWordpressWpProductCategory {
      edges {
        node {
          name
          slug
          path
          count
          wordpress_id
          wordpress_parent
        }
      }
    }
    siteSettings: wordpressAcfOptions(options: {}) {
      options {
        newsletterText
      }
    }
  }
`
